import { EmploymentContractService } from '@/modules/employment-contract/employment-contract-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';

export class EmploymentContractField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/employment-contract',
      Permissions.values.employmentContractRead,
      Permissions.values.employmentContractNavigate,
      EmploymentContractService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        if (record.teamMember != null) {
          //TODO
          //this is in a case when a new entry is created in from the portal and we get a response from create which is different from autocomplete
          // this should be unified at some point
          
          return {
            id: record.id,
            label: record.teamMember.name,
          };
        }
        else {
          //This is coming from autocomplete, here we already have label/name sent from the server
          return {
            id: record.id,
            label: record.teamMember.name,
          };
        }
      },
      options,
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/employment-contract',
      Permissions.values.employmentContractRead,
      Permissions.values.employmentContractNavigate,
      EmploymentContractService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }
        if (record.teamMember != null) {
          //TODO
          //this is in a case when a new entry is created in from the portal and we get a response from create which is different from autocomplete
          // this should be unified at some point 
          return {
            id: record.id,
            label: record.teamMember.name,
          };
        }
        else {
          //This is coming from autocomplete, here we already have label/name sent from the server
          return {
            id: record.id,
            label: record.teamMember.name,
          };
        }
      },
      options,
    );
  }
}

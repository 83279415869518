import { i18n } from '@/i18n';
import { SettingsStorage } from '@/modules/settings/settings-storage';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { CooperationPartnerContractField } from '@/modules/cooperation-partner-contract/cooperation-partner-contract-field';
import { EmploymentContractField } from '@/modules/employment-contract/employment-contract-field';
import FilesField from '@/shared/fields/files-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';

function label(name) {
  return i18n(`entities.projectImplementation.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.projectImplementation.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  ethicsCommitteeEnum: new EnumeratorField(
    'ethicsCommitteeEnum',
    label('ethicsCommitteeEnum'),
    [
      {
        id: 'yes',
        label: enumeratorLabel('ethicsCommitteeEnum', 'yes'),
      },
      {
        id: 'no',
        label: enumeratorLabel('ethicsCommitteeEnum', 'no'),
      },
    ],
    {
      required: true,
    },
  ),
  ethicsCommitteeDecision: new FilesField(
    'ethicsCommitteeDecision',
    label('ethicsCommitteeDecision'),
    Storage.values.projectImplementationEthicsCommitteeDecision,
    Permissions.getFilePermissions(
      'projectImplementationFileFields',
      'ethicsCommitteeDecision',
    ),
    {
      required: true,
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  cooperationContractsEnum: new EnumeratorField(
    'cooperationContractsEnum',
    label('cooperationContractsEnum'),
    [
      {
        id: 'yes',
        label: enumeratorLabel('cooperationContractsEnum', 'yes'),
      },
      {
        id: 'no',
        label: enumeratorLabel('cooperationContractsEnum', 'no'),
      },
    ],
    {
      required: true,
    },
  ),
  cooperationPartnerContracts: CooperationPartnerContractField.relationToMany(
    'cooperationPartnerContracts',
    label('cooperationPartnerContracts'),
    {
      required: true,
    },
  ),
  employmentContractsEnum: new EnumeratorField(
    'employmentContractsEnum',
    label('employmentContractsEnum'),
    [
      {
        id: 'yes',
        label: enumeratorLabel('employmentContractsEnum', 'yes'),
      },
      {
        id: 'no',
        label: enumeratorLabel('employmentContractsEnum', 'no'),
      },
    ],
    {
      required: true,
    },
  ),
  employmentContracts: EmploymentContractField.relationToMany(
    'employmentContracts',
    label('employmentContracts'),
    {
      required: true,
    },
  ),
  status: new EnumeratorField(
    'status',
    label('status'),
    [
      {
        id: 'projectNoAssignmentByAccounting',
        label: enumeratorLabel(
          'status',
          'projectNoAssignmentByAccounting',
        ),
      },
      {
        id: 'grantApplication',
        label: enumeratorLabel('status', 'grantApplication'),
      },
      {
        id: 'completed',
        label: enumeratorLabel('status', 'completed'),
      },
    ],
    { required: true },
  ),

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class ProjectImplementationModel extends GenericModel {
  static get fields() {
    return fields;
  }
}

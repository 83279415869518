import { CooperationPartnerContractService } from '@/modules/cooperation-partner-contract/cooperation-partner-contract-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';

export class CooperationPartnerContractField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/cooperation-partner-contract',
      Permissions.values.cooperationPartnerContractRead,
      Permissions.values.cooperationPartnerContractNavigate,
      CooperationPartnerContractService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        if (record.cooperationPartner != null) {
          //TODO
          //this is in a case when a new entry is created in from the portal and we get a response from create which is different from autocomplete
          // this should be unified at some point
          label = `${record.cooperationPartner.institutionName}`;

          if (record.cooperationPartner.title) {
            label = `${record.cooperationPartner.title} ${record.cooperationPartner.firstName} ${record.cooperationPartner.lastName} <${record.cooperationPartner.emailAddress}>`;
          }
          else {
            label = `${record.cooperationPartner.firstName} ${record.cooperationPartner.lastName} <${record.cooperationPartner.emailAddress}>`;
          }

          return {
            id: record.id,
            label: label,
          };
        }
        else {
          //This is coming from autocomplete, here we already have label/name sent from the server
          return {
            id: record.id,
            label: record.name,
          };
        }
      },
      options,
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/cooperation-partner-contract',
      Permissions.values.cooperationPartnerContractRead,
      Permissions.values.cooperationPartnerContractNavigate,
      CooperationPartnerContractService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        if (record.cooperationPartner != null) {
          //TODO
          //this is in a case when a new entry is created in from the portal and we get a response from create which is different from autocomplete
          // this should be unified at some point
          label = `${record.cooperationPartner.institutionName}`;

          if (record.cooperationPartner.partner === 'person') {
            if (record.cooperationPartner.title) {
              label = `${record.cooperationPartner.title} ${record.cooperationPartner.firstName} ${record.cooperationPartner.lastName} <${record.cooperationPartner.emailAddress}>`;
            }
            else {
              label = `${record.cooperationPartner.firstName} ${record.cooperationPartner.lastName} <${record.cooperationPartner.emailAddress}>`;
            }
          }

          return {
            id: record.id,
            label: label,
          };
        }
        else {
          //This is coming from autocomplete, here we already have label/name sent from the server
          return {
            id: record.id,
            label: record.name,
          };
        }
      },
      options,
    );
  }
}

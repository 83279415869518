<template>
  <div>
    <div
      class="app-page-spinner"
      v-if="loading"
      v-loading="loading"
    ></div>
    <br />
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      @submit.prevent.native
      class="form"
      v-if="record"
    >
      <app-view-item-text
        :label="fields.ethicsCommitteeEnum.label"
        :value="presenter(record, 'ethicsCommitteeEnum')"
      ></app-view-item-text>

      <app-view-item-file
        :label="fields.ethicsCommitteeDecision.label"
        :value="presenter(record, 'ethicsCommitteeDecision')"
        :permissions="
          fields.ethicsCommitteeDecision.permissions
        "
      ></app-view-item-file>

      <app-view-item-text
        :label="fields.cooperationContractsEnum.label"
        :value="presenter(record, 'cooperationContractsEnum')"
      ></app-view-item-text>

      <app-view-item-relation-to-many
        :label="fields.cooperationPartnerContracts.label"
        :readPermission="
          fields.cooperationPartnerContracts.readPermission
        "
        :navigatePermission="fields.cooperationPartnerContracts.navigatePermission"
        :value="presenter(record, 'cooperationPartnerContracts')"
      ></app-view-item-relation-to-many>

      <app-view-item-text
        :label="fields.employmentContractsEnum.label"
        :value="presenter(record, 'employmentContractsEnum')"
      ></app-view-item-text>

      <app-view-item-relation-to-many
        :label="fields.employmentContracts.label"
        :readPermission="fields.employmentContracts.readPermission"
        :navigatePermission="fields.employmentContracts.navigatePermission"
        :value="presenter(record, 'employmentContracts')"
      ></app-view-item-relation-to-many>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ProjectImplementationModel } from '@/modules/project-implementation/project-implementation-model';

const { fields } = ProjectImplementationModel;

export default {
  name: 'app-project-implementation-view-page',

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'projectImplementation/view/record',
      loading: 'projectImplementation/view/loading',
      projectImplementationId: 'project/form/projectImplementationId',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.projectImplementationId);
  },

  methods: {
    ...mapActions({
      doFind: 'projectImplementation/view/doFind',
    }),

    presenter(record, fieldName) {
      return ProjectImplementationModel.presenter(record, fieldName);
    },
  },
};
</script>
